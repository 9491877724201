import React from 'react';

interface IProps {
   onClick: () => void;
}

const CheckOn: React.FC<IProps> = ({ onClick }) => (
   <svg onClick={onClick} width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_186_9300)">
         <path d="M17.5 0.875H3.5C2.80381 0.875 2.13613 1.15156 1.64384 1.64384C1.15156 2.13613 0.875 2.80381 0.875 3.5V17.5C0.875 18.1962 1.15156 18.8639 1.64384 19.3562C2.13613 19.8484 2.80381 20.125 3.5 20.125H17.5C18.1962 20.125 18.8639 19.8484 19.3562 19.3562C19.8484 18.8639 20.125 18.1962 20.125 17.5V3.5C20.125 2.80381 19.8484 2.13613 19.3562 1.64384C18.8639 1.15156 18.1962 0.875 17.5 0.875ZM16.3686 6.74362L8.49362 14.6186C8.32954 14.7827 8.10702 14.8748 7.875 14.8748C7.64298 14.8748 7.42046 14.7827 7.25638 14.6186L4.63138 11.9936C4.47199 11.8286 4.38379 11.6076 4.38579 11.3781C4.38778 11.1487 4.4798 10.9293 4.64203 10.767C4.80427 10.6048 5.02373 10.5128 5.25315 10.5108C5.48257 10.5088 5.7036 10.597 5.86862 10.7564L7.875 12.7628L15.1314 5.50638C15.2121 5.4228 15.3086 5.35614 15.4154 5.31029C15.5222 5.26443 15.637 5.24029 15.7531 5.23928C15.8693 5.23827 15.9845 5.26041 16.0921 5.30441C16.1996 5.3484 16.2973 5.41337 16.3795 5.49553C16.4616 5.57769 16.5266 5.67538 16.5706 5.78292C16.6146 5.89045 16.6367 6.00567 16.6357 6.12185C16.6347 6.23803 16.6106 6.35285 16.5647 6.4596C16.5189 6.56636 16.4522 6.66291 16.3686 6.74362Z" fill="#8787A1"/>
      </g>
      <defs>
         <clipPath id="clip0_186_9300">
            <rect width="21" height="21" fill="white"/>
         </clipPath>
      </defs>
   </svg>
);

export default CheckOn;