import './bootstrap';

import React, { StrictMode, Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from "react-query";
import { createRoot } from 'react-dom/client';

import ScrollToTop from "wrappers/ScrollToTop";
import App from './App';

import Spinner from "components/Spinner";

const queryClient = new QueryClient({
   defaultOptions: {
      queries: {
         refetchOnWindowFocus: false
      },
   },
})

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
   <StrictMode>
      <Suspense
        fallback={
           <div
             style={{
                position: 'fixed',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100vw',
                height: '100vh',
             }}
           >
              <Spinner />
           </div>
        }
      >
         <QueryClientProvider client={queryClient}>
            <BrowserRouter>
               <ScrollToTop>
                  <App />
               </ScrollToTop>
            </BrowserRouter>
         </QueryClientProvider>
      </Suspense>
   </StrictMode>
);