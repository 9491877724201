import React from 'react';
import { useTranslation } from "react-i18next";

import * as Icons from 'assets/icons';

import * as Hooks from 'modules/useful/hooks';
import { TYPE } from "modules/useful/constants";

import Container from "components/Container";
import Networks from "./components/Networks";

import cls from './Footer.module.scss';

const Footer: React.FC = () => {
   const { t, i18n } = useTranslation();

   const { items } = Hooks.useList();

   const scrollToTop = () => {
      window.scrollTo({
         top: 0,
         behavior: "smooth"
      });
   }

   return (
      <footer className={cls.footer}>
         <Container>
            <div className={cls.content}>
               <div className={cls.box}>
                  {/* @ts-ignore */}
                  <span className={cls.title}>{t('connection')}</span>

                  <ul className={cls.list}>
                     {/* @ts-ignore */}
                     <li>{t('address')}</li>
                     {/* @ts-ignore */}
                     <li>{t('address_location')}</li>
                     {/* @ts-ignore */}
                     <li>{t('tel')}: (71) 207-00-66</li>
                     {/* @ts-ignore */}
                     <li>{t('short_number')}: 1148</li>
                     {/* @ts-ignore */}
                     <li>{t('email')}: advice@adliya.uz</li>
                  </ul>

                  <Networks />
               </div>

               <div className={cls.box}>
                  {/* @ts-ignore */}
                  <span className={cls.title}>{t('useful_resources')}</span>

                  <ul className={cls.list}>
                     {/* eslint-disable-next-line array-callback-return */}
                     {items.map(item => {
                        if (item.type === TYPE.RESOURCE) {
                           return (
                              <li key={item.id}>
                                 <a href={item.url}>{item.name[i18n.language]}</a>
                              </li>
                           )
                        }
                     })}
                  </ul>
               </div>

               <div className={cls.box}>
                  {/* @ts-ignore */}
                  <span className={cls.title}>{t('useful_resources2')}</span>

                  <ul className={cls.list}>
                     {/* eslint-disable-next-line array-callback-return */}
                     {items.map(item => {
                        if (item.type === TYPE.ABOUT_PORTAL) {
                           return (
                              <li key={item.id}>
                                 <a href={item.url}>{item.name[i18n.language]}</a>
                              </li>
                           )
                        }
                     })}
                  </ul>
               </div>

               <div className={cls.box}>
                  {/* @ts-ignore */}
                  <span className={cls.title}>{t('mobile_applications')}</span>

                  <ul className={cls.list}>
                     <li>
                        <a href="https://apps.apple.com/us/app/id1533252839" target="_blank" rel='noreferrer'>
                           <img src={require('assets/images/mobile/app_store.svg').default} alt="App Store" width={180} height={52} />
                        </a>
                     </li>
                     <li>
                        <a href="https://play.google.com/store/apps/details?id=uz.business.advice" target="_blank" rel='noreferrer'>
                           <img src={require('assets/images/mobile/google_play.svg').default} alt="Google Play" width={180} height={52} />
                        </a>
                     </li>
                  </ul>
               </div>
            </div>

            <div onClick={scrollToTop} className={cls.scrollIcon}>
               <Icons.ArrowTop />
            </div>
         </Container>
      </footer>
   )
};

export default Footer;
