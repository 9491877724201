import React, { useState } from 'react';
import cx from 'classnames';

import cls from './Input.module.scss';

export interface IProps {
  name?: string;
  value?: string;
  type?: 'text' | 'password' | 'tel';
  state?: 'default' | 'success' | 'error';
  placeholder?: string;
  border?: boolean;
  disabled?: boolean;
  pattern?: string;
  className?: string;
  onChange?: (value: string) => void;
  onKeyPress?: (value: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: any;
  iconSuffix?: React.ReactNode;
  iconPrefix?: React.ReactNode;
}

const Input: React.FC<IProps> = ({
  name,
  value,
  type = 'text',
  state = 'default',
  placeholder,
  border = true,
  disabled,
  pattern,
  className,
  onChange,
  onKeyPress,
  onBlur,
  iconSuffix,
  iconPrefix,
}) => {
  const [isFocused, setFocused] = useState(false);

  return (
     <div
        className={cx(
           cls.wrapper,
           iconSuffix && cls['wrapper--has-iconSuffix'],
           iconPrefix && cls['wrapper--has-iconPrefix']
        )}
     >
       {iconSuffix && (<div className={cls.iconSuffix}>{iconSuffix}</div>)}
       <input
          className={cx(
             cls.input,
             state && cls[`input--state-${state}`],
             border && cls['input--border'],
             isFocused && cls['input--focused'],
             className
          )}
          {...{ value, type, name, placeholder, disabled, pattern }}
          onChange={e => onChange && onChange(e.target.value)}
          onKeyPress={e => onKeyPress && onKeyPress(e)}
          onFocus={() => setFocused(true)}
          onBlur={e => {
            setFocused(false);
            onBlur && onBlur(e);
          }}
          autoComplete="off"
       />
       {iconPrefix && (<div className={cls.iconPrefix}>{iconPrefix}</div>)}
     </div>
  );
};

export default Input;
