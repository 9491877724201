import React, { cloneElement, isValidElement } from 'react';
import cx from 'classnames';

import cls from './Button.module.scss';

interface IProps {
   title?: string;
   size?: 'sm' | 'md' | 'lg';
   variant?: 'white' | 'secondary' | 'primary';
   prefixIcon?: React.ReactNode;
   suffixIcon?: React.ReactNode;
   block?: boolean;
   type?: 'submit' | 'reset' | 'button';
   form?: string;
   className?: string;
   disabled?: boolean;
   container?: React.ReactNode;
   onClick?: () => void;
}

const Button: React.FC<IProps> = ({
 title,
 size = 'md',
 variant,
 prefixIcon,
 suffixIcon,
 block,
 type = 'button',
 form,
 className,
 disabled,
 container,
 onClick
}) => {
   const resultProps = {
      className: cx(
         cls.wrapper,
         cls[`wrapper--size-${size}`],
         cls[`wrapper--variant-${variant}`],
         block && cls[`wrapper--block`],
         className
      ),
      onClick,
      form,
      disabled
   };

   const resultChildren = (
      <>
         {!!prefixIcon && <div className={cls.icon}>{prefixIcon}</div>}
         {!!title && <div className={cls.title}>{title}</div>}
         {!!suffixIcon && <div className={cls.icon}>{suffixIcon}</div>}
      </>
   );

   const resultContainer = isValidElement(container) ? container : <button type={type} />;

   return cloneElement(resultContainer, resultProps, resultChildren);
};

export default Button;
