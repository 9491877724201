import React from 'react';

const Email: React.FC = () => (
   <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M1.25 4.5C1.25 4.08579 1.58579 3.75 2 3.75H22C22.4142 3.75 22.75 4.08579 22.75 4.5V19.5C22.75 19.9142 22.4142 20.25 22 20.25H2C1.58579 20.25 1.25 19.9142 1.25 19.5V4.5ZM2.75 5.25V18.75H21.25V5.25H2.75Z" fill="#8787A1"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M1.40004 4.04998C1.64857 3.71861 2.11867 3.65145 2.45004 3.89998L12 11.0625L21.55 3.89998C21.8814 3.65145 22.3515 3.71861 22.6 4.04998C22.8486 4.38135 22.7814 4.85145 22.45 5.09998L12.45 12.6C12.1834 12.8 11.8167 12.8 11.55 12.6L1.55004 5.09998C1.21867 4.85145 1.15152 4.38135 1.40004 4.04998Z" fill="#8787A1"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M1.25 4.5C1.25 4.08579 1.58579 3.75 2 3.75H12C12.4142 3.75 12.75 4.08579 12.75 4.5C12.75 4.91421 12.4142 5.25 12 5.25H2.75V12C2.75 12.4142 2.41421 12.75 2 12.75C1.58579 12.75 1.25 12.4142 1.25 12V4.5Z" fill="#8787A1"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M11.25 4.5C11.25 4.08579 11.5858 3.75 12 3.75H22C22.4142 3.75 22.75 4.08579 22.75 4.5V12C22.75 12.4142 22.4142 12.75 22 12.75C21.5858 12.75 21.25 12.4142 21.25 12V5.25H12C11.5858 5.25 11.25 4.91421 11.25 4.5Z" fill="#8787A1"/>
   </svg>
);

export default Email;