import React from 'react';
import { NavLink } from "react-router-dom";

import { IItem } from './Menu';

import cls from './Menu.module.scss';

interface IProps extends IItem {}

const SubMenuItem: React.FC<IProps> = ({ name, path, isRoute }) => (
   <li>
      { isRoute
         ? <NavLink className={cls.subMenuLink} to={path}>{name}</NavLink>
         : <a className={cls.subMenuLink} href={path} target='_blank' rel="noreferrer">{name}</a>
      }
   </li>
);

export default SubMenuItem;