import React from 'react';

import * as Icons from "assets/icons";

import { ISubMenu } from './Menu';
import SubMenuItem from "./SubMenuItem";

import cls from './Menu.module.scss';

interface IProps extends ISubMenu {}

const SubMenu: React.FC<IProps> = ({ name, menus }) => (
   <li className={cls.subMenu}>
      <div className={cls.subMenuContent}>
         <span className={cls.subMenuName}>{name}</span>
         <Icons.Bottom />
      </div>

      <div className={cls.subMenuDropdown}>
         <ul className={cls.subMenuList}>
            {menus.map((menu, index) => (
               <SubMenuItem
                  key={index}
                  name={menu.name}
                  path={menu.path}
                  isRoute={menu.isRoute}
               />
            ))}
         </ul>
      </div>
   </li>
);

export default SubMenu;