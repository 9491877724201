import React from 'react';

const Print: React.FC = () => (
   <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M4.25 4C4.25 3.03351 5.03348 2.25 6 2.25H18C18.9665 2.25 19.75 3.03351 19.75 4V10C19.75 10.4142 19.4142 10.75 19 10.75C18.5858 10.75 18.25 10.4142 18.25 10V4C18.25 3.86192 18.1381 3.75 18 3.75H6C5.86192 3.75 5.75 3.86192 5.75 4V10C5.75 10.4142 5.41421 10.75 5 10.75C4.58579 10.75 4.25 10.4142 4.25 10V4Z" fill="#8787A1"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M4 10.75C3.86193 10.75 3.75 10.8619 3.75 11V20C3.75 20.1381 3.86193 20.25 4 20.25H20C20.1381 20.25 20.25 20.1381 20.25 20V11C20.25 10.8619 20.1381 10.75 20 10.75H4ZM2.25 11C2.25 10.0335 3.0335 9.25 4 9.25H20C20.9665 9.25 21.75 10.0335 21.75 11V20C21.75 20.9665 20.9665 21.75 20 21.75H4C3.0335 21.75 2.25 20.9665 2.25 20V11Z" fill="#8787A1"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M9.25 17C9.25 16.5858 9.58579 16.25 10 16.25H17.5C17.9142 16.25 18.25 16.5858 18.25 17V21C18.25 21.4142 17.9142 21.75 17.5 21.75H10C9.58579 21.75 9.25 21.4142 9.25 21V17ZM10.75 17.75V20.25H16.75V17.75H10.75Z" fill="#8787A1"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M5.25 13C5.25 12.5858 5.58579 12.25 6 12.25H7.5C7.91421 12.25 8.25 12.5858 8.25 13C8.25 13.4142 7.91421 13.75 7.5 13.75H6C5.58579 13.75 5.25 13.4142 5.25 13Z" fill="#8787A1"/>
   </svg>
);

export default Print;