import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import 'assets/styles/main.scss';

import config from 'config';

// import { store } from 'store';

import { http, i18n, storage } from 'services';

i18n.init({
   languages: config.language.list,
   currentLanguage: storage.local.get(config.language.key),
   initialLanguage: config.language.initial,
   backend: {
      loadPath: `${config.api.base_url}/api/v1/client/translations/{{lng}}`
   },
   onChange: language => storage.local.set('language', language)
});

http.init({
   configFn: () => {
      // const state = store.getState();
      // const token = state.auth.tokens.accessToken;
      const token = '';

      return {
         baseURL: config.api.base_url,
         headers: {
            ...(token ? { Authorization: `Bearer ${token}` } : {})
         }
      };
   }
});