const ENV = process.env;

const config = {
  sso: {
    link: ENV.REACT_APP_SSO_LINK
  },
  app: {
    env: ENV.REACT_APP_ENV,
    version: ENV.REACT_APP_VERSION,
    isDev: ENV.REACT_APP_ENV !== 'production',
    public_url: ENV.PUBLIC_URL
  },
  language: {
    key: 'language',
    initial: 'uz',
    list: ['uz', 'oz', 'ru', 'en']
  },
  api: {
    base_url: ENV.REACT_APP_SERVER_URL
  },
  sentry: {
    dsn: ENV.REACT_APP_SENTRY_DSN
  },
  list: {
    size: 9
  }
};

export default config;
