import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import cx from "classnames";

import * as Icons from 'assets/icons';

import cls from './Language.module.scss';

const langs = [
   { key: 'uz', value: 'o\'z', title: 'O\'zbekcha' },
   { key: 'oz', value: 'ўз', title: 'Ўзбекча' },
   { key: 'ru', value: 'ru', title: 'Русский' },
   { key: 'en', value: 'en', title: 'English' }
];

const Language: React.FC = () => {
   const { i18n } = useTranslation();
   const [show, setShow] = useState(false);

   useEffect(() => {
      const wrapper = document.querySelector('.app');
      let cancel = false;

      wrapper?.addEventListener('click', () => {
         if (cancel) return;

         setShow(false);
      });

      return () => {
         cancel = true;
      }
   }, []);

   return (
      <div className={cls.wrapper}>
         <strong onClick={() => setShow(!show)} className={cls.selected}>
            {langs.find(lang => lang.key === i18n.language)?.value} <Icons.Bottom />
         </strong>

         <ul className={cx(cls.languages, show && cls['languages--show'])}>
            {langs.map(lang => (
               <li
                  onClick={() => i18n.changeLanguage(lang.key)}
                  className={cls.language}
                  key={lang.key}
               >
                  {lang.title}
               </li>
            ))}
         </ul>
      </div>
   )
}

export default Language;