import React from 'react';

const AddPlus: React.FC = () => (
   <svg width="30" height="30" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_56_14532" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
         <rect width="20" height="20" fill="#D9D9D9"/>
      </mask>
      <g mask="url(#mask0_56_14532)">
         <path d="M9.2 14H10.8V10.8H14V9.2H10.8V6H9.2V9.2H6V10.8H9.2V14ZM10 18C8.89333 18 7.85333 17.7899 6.88 17.3696C5.90667 16.9499 5.06 16.38 4.34 15.66C3.62 14.94 3.05013 14.0933 2.6304 13.12C2.21013 12.1467 2 11.1067 2 10C2 8.89333 2.21013 7.85333 2.6304 6.88C3.05013 5.90667 3.62 5.06 4.34 4.34C5.06 3.62 5.90667 3.04987 6.88 2.6296C7.85333 2.20987 8.89333 2 10 2C11.1067 2 12.1467 2.20987 13.12 2.6296C14.0933 3.04987 14.94 3.62 15.66 4.34C16.38 5.06 16.9499 5.90667 17.3696 6.88C17.7899 7.85333 18 8.89333 18 10C18 11.1067 17.7899 12.1467 17.3696 13.12C16.9499 14.0933 16.38 14.94 15.66 15.66C14.94 16.38 14.0933 16.9499 13.12 17.3696C12.1467 17.7899 11.1067 18 10 18ZM10 16.4C11.7867 16.4 13.3 15.78 14.54 14.54C15.78 13.3 16.4 11.7867 16.4 10C16.4 8.21333 15.78 6.7 14.54 5.46C13.3 4.22 11.7867 3.6 10 3.6C8.21333 3.6 6.7 4.22 5.46 5.46C4.22 6.7 3.6 8.21333 3.6 10C3.6 11.7867 4.22 13.3 5.46 14.54C6.7 15.78 8.21333 16.4 10 16.4Z" fill="white"/>
      </g>
   </svg>
);

export default AddPlus;