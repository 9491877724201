import React from 'react';

import Header from "./Header";
import Footer from "./Footer";

interface IProps {
  children: React.ReactNode;
}

const Layout: React.FC<IProps> = ({ children }) => (
   <div className='app'>
      <Header />

      <main>
         {children}
      </main>

      <Footer />
   </div>
);

export default Layout;
