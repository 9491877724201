import React from 'react';

const Download: React.FC = () => (
   <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_64_14582" maskUnits="userSpaceOnUse" x="0" y="0" width="21" height="20">
         <rect x="0.5" width="20" height="20" fill="#D9D9D9"/>
      </mask>
      <g mask="url(#mask0_64_14582)">
         <path d="M5.50065 16.6663C5.04232 16.6663 4.6501 16.5033 4.32398 16.1772C3.99732 15.8505 3.83398 15.458 3.83398 14.9997V12.4997H5.50065V14.9997H15.5007V12.4997H17.1673V14.9997C17.1673 15.458 17.0043 15.8505 16.6782 16.1772C16.3515 16.5033 15.959 16.6663 15.5007 16.6663H5.50065ZM10.5007 13.333L6.33398 9.16634L7.50065 7.95801L9.66732 10.1247V3.33301H11.334V10.1247L13.5007 7.95801L14.6673 9.16634L10.5007 13.333Z" fill="#196DEB"/>
      </g>
   </svg>
);

export default Download;