import React from 'react';

const Date: React.FC = () => (
   <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_130_16073" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="23">
         <rect y="0.5" width="24" height="22" fill="#D9D9D9"/>
      </mask>
      <g mask="url(#mask0_130_16073)">
         <path d="M9 15.6252C8.3 15.6252 7.70833 15.4036 7.225 14.9606C6.74167 14.5175 6.5 13.9752 6.5 13.3335C6.5 12.6918 6.74167 12.1495 7.225 11.7064C7.70833 11.2634 8.3 11.0418 9 11.0418C9.7 11.0418 10.2917 11.2634 10.775 11.7064C11.2583 12.1495 11.5 12.6918 11.5 13.3335C11.5 13.9752 11.2583 14.5175 10.775 14.9606C10.2917 15.4036 9.7 15.6252 9 15.6252ZM5 20.6668C4.45 20.6668 3.979 20.4875 3.587 20.1287C3.19567 19.7694 3 19.3377 3 18.8335V6.00016C3 5.496 3.19567 5.06455 3.587 4.70583C3.979 4.3465 4.45 4.16683 5 4.16683H6V2.3335H8V4.16683H16V2.3335H18V4.16683H19C19.55 4.16683 20.021 4.3465 20.413 4.70583C20.8043 5.06455 21 5.496 21 6.00016V18.8335C21 19.3377 20.8043 19.7694 20.413 20.1287C20.021 20.4875 19.55 20.6668 19 20.6668H5ZM5 18.8335H19V9.66683H5V18.8335ZM5 7.8335H19V6.00016H5V7.8335Z" fill="#8787A1"/>
      </g>
   </svg>
);

export default Date;