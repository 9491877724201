import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

interface IProps {
   children: React.ReactNode;
}

const ScrollToTop: React.FC<IProps> = ({ children }) => {
   const { pathname } = useLocation();

   useEffect(() => {
      window.scrollTo(0, 0);
   }, [pathname]);

   return <>{children}</>;
}

export default ScrollToTop;