import React from 'react';

const Media: React.FC = () => (
   <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_64_14556" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="25">
         <rect x="0.5" y="0.5" width="24" height="24" fill="#D9D9D9"/>
      </mask>
      <g mask="url(#mask0_64_14556)">
         <path d="M5.5 22.5C4.95 22.5 4.479 22.3043 4.087 21.913C3.69567 21.521 3.5 21.05 3.5 20.5H5.5V22.5ZM3.5 19V17H5.5V19H3.5ZM3.5 15.5V13.5H5.5V15.5H3.5ZM3.5 12V10H5.5V12H3.5ZM3.5 8.5C3.5 7.95 3.69567 7.47933 4.087 7.088C4.479 6.696 4.95 6.5 5.5 6.5V8.5H3.5ZM7 22.5V20.5H9V22.5H7ZM9.5 18.5C8.95 18.5 8.47933 18.3043 8.088 17.913C7.696 17.521 7.5 17.05 7.5 16.5V4.5C7.5 3.95 7.696 3.479 8.088 3.087C8.47933 2.69567 8.95 2.5 9.5 2.5H18.5C19.05 2.5 19.521 2.69567 19.913 3.087C20.3043 3.479 20.5 3.95 20.5 4.5V16.5C20.5 17.05 20.3043 17.521 19.913 17.913C19.521 18.3043 19.05 18.5 18.5 18.5H9.5ZM9.5 16.5H18.5V4.5H9.5V16.5ZM10.5 22.5V20.5H12.5V22.5H10.5ZM14 22.5V20.5H16C16 21.05 15.8043 21.521 15.413 21.913C15.021 22.3043 14.55 22.5 14 22.5Z" fill="#1B2435"/>
      </g>
   </svg>
);

export default Media;