import React from 'react';
import { NavLink } from "react-router-dom";
import cx from "classnames";

import { IItem } from './Menu';

import cls from './Menu.module.scss';

interface IProps extends IItem {}

const MenuItem: React.FC<IProps> = ({ name, path , isRoute }) => (
   <li className={cls.menuItem}>
      { isRoute
         ?  <NavLink className={({ isActive }) =>
               cx(cls.menuItemLink, isActive && cls['menuItemLink--active'])}
               to={path}
            >
            {name}
            </NavLink>
         : <a className={cls.menuItemLink} href={path}>{name}</a>
      }
   </li>
);

export default MenuItem;