import React from 'react';

import SubMenu from "./SubMenu";
import MenuItem from "./MenuItem";

import cls from './Menu.module.scss';

export interface IItem {
   name: string;
   path: string;
   isRoute?: boolean;
}

export interface ISubMenu {
   name: string;
   menus: IItem[];
}

export interface IMenu {
   name: string;
   path: string;
   subMenu?: ISubMenu;
   isRoute?: boolean;
}

export interface IProps {
   menus: IMenu[];
}

const Menu: React.FC<IProps> = ({ menus }) => (
   <ul className={cls.menu}>
      {menus.map((menu, index) => {
         if (!!menu.subMenu) {
            return (
               <SubMenu
                  key={index}
                  name={menu.subMenu.name}
                  menus={menu.subMenu.menus}
               />
            )
         }

         return (
            <MenuItem
               key={index}
               name={menu.name}
               path={menu.path}
               isRoute={menu.isRoute}
            />
         )
      })}
   </ul>
);

export default Menu;