import React from 'react';

interface IProps {
   variant?: '#FFFFFF' | '#8787A1' | '#196DEB';
}

const Search: React.FC<IProps> = ({ variant = '#FFFFFF' }) => (
   <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.75008 15.8334C12.6621 15.8334 15.8334 12.6621 15.8334 8.75008C15.8334 4.83808 12.6621 1.66675 8.75008 1.66675C4.83808 1.66675 1.66675 4.83808 1.66675 8.75008C1.66675 12.6621 4.83808 15.8334 8.75008 15.8334Z" stroke={variant} strokeWidth="2" strokeLinejoin="round"/>
      <path d="M13.8425 13.8425L17.3781 17.3781" stroke={variant} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
   </svg>
);

export default Search;