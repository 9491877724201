import React from 'react';

import * as Helpers from "helpers";
import * as Hooks from 'modules/network/hooks';

import cls from '../Footer.module.scss';

const Networks: React.FC = () => {
   const { items } = Hooks.useList();

   return (
      <ul className={cls.network}>
         {items.map(item => (
            <li key={item.id}>
               <a className={cls.networkLink} href={item.url} target='_blank' rel='noreferrer'>
                  <img src={Helpers.Image.Url(item.photoId)} width={24} height={24} alt='Network' />
               </a>
            </li>
         ))}
      </ul>
   )
};

export default Networks;