import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useUrlSearchParams } from "use-url-search-params";
import cx from "classnames";

import * as Icons from 'assets/icons';

import Menu from "components/Menu";
import Input from "components/Input";
import Button from "components/Button";
import Language from "components/Language";
import Container from "components/Container";

import cls from './Header.module.scss';

const Header: React.FC = () => {
   const { t } = useTranslation();
   const location = useLocation();
   const navigate = useNavigate();
   const [isScroll, setIsScroll] = useState(false);
   const [params, setParams] = useUrlSearchParams();

   const isMainPage = location.pathname === '/';

   const menus = [
      {
         name: t('about_project'),
         path: '/about-project',
         subMenu: {
            name: t('about_project'),
            menus: [
               { name: t('advice_for_business'), path: '/info/advice_for_business', isRoute: true },
               { name: t('about_us'), path: '/info/about_us', isRoute: true },
               { name: t('experts'), path: '/experts', isRoute: true },
               { name: t('how_work'), path: '/info/how_work', isRoute: true },
            ]
         },
         isRoute: true
      },
      { name: t('discover'), path: '/discover/main', isRoute: true },
      { name: t('business_indikator'), path: 'https://b-indicator.uz', isRoute: false },
      { name: t('export'), path: '/export/main', isRoute: true },
      { name: t('faq'), path: '/#faq', isRoute: false },
      { name: t('hotlines'), path: '/hotlines', isRoute: true },
      { name: t('to_investors'), path: '/investor/main', isRoute: true }
   ];

   const handleScroll = () => {
      if(window.scrollY >= 10){
         setIsScroll(true);
      } else {
         setIsScroll(false);
      }
   };

   useEffect(() => {
      window.addEventListener('scroll', handleScroll);
   }, []);

   return (
      <header
         className={cx(
            cls.header,
            isMainPage ? '' : cls['header--white'],
            isScroll && cls['header--scroll']
         )}>
         <div className={cls.top}>
            <Container>
               <div className={cls.content}>
                  <Link className={cls.flex} to='/'>
                     <Icons.Logo />

                     <div className={cls.divider} />

                     {/* @ts-ignore */}
                     <strong className={cls.text}>{t('advice_for_business')}</strong>
                  </Link>

                  <div className={cls.flex}>
                     <div className={cls.search}>
                        <Input
                           onChange={e => setParams({ text: e })}
                           onKeyPress={e => {
                              if(e.key === 'Enter'){
                                 navigate(`/search?text=${params.text}`);
                              }
                           }}
                           placeholder={t('main_search')}
                           value={params.text ? String(params.text) : ''}
                           border={false}
                        />
                        <div onClick={() => navigate(`/search?text=${params.text}`)} className={cls.searchIcon}>
                           <Icons.Search variant='#196DEB' />
                        </div>
                     </div>

                     <Language />

                     {/*<Button*/}
                     {/*   title={t('login')}*/}
                     {/*   variant='primary'*/}
                     {/*/>*/}

                     <Button
                        title={t('old_b_advice')}
                        variant='primary'
                        container={
                           // eslint-disable-next-line jsx-a11y/anchor-has-content
                           <a href="http://old.b-advice.uz/" target="_blank" rel="noreferrer" />
                        }
                     />
                  </div>
               </div>
            </Container>
         </div>

         <div className={cls.bottom}>
            <Container>
               <Menu
                  menus={menus}
               />

            </Container>
         </div>
      </header>
   )
};

export default Header;