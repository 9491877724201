import get from 'lodash/get';

import * as Types from './types';

export const Useful = (item?): Types.IEntity.Useful => ({
  id: get(item, 'id') || '',
  name: {
    uz: get(item, 'name.uz') || '',
    oz: get(item, 'name.oz') || '',
    ru: get(item, 'name.ru') || '',
    en: get(item, 'name.en') || '',
  },
  url: get(item, 'url') || '',
  sort: get(item, 'sort') || '',
  type: get(item, 'type') || ''
})