import React from 'react';

const Share: React.FC = () => (
   <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M14.25 5.5C14.25 3.70507 15.7051 2.25 17.5 2.25C19.2949 2.25 20.75 3.70507 20.75 5.5C20.75 7.29491 19.2949 8.75 17.5 8.75C15.7051 8.75 14.25 7.29491 14.25 5.5ZM17.5 3.75C16.5335 3.75 15.75 4.53351 15.75 5.5C15.75 6.46649 16.5335 7.25 17.5 7.25C18.4665 7.25 19.25 6.46649 19.25 5.5C19.25 4.53351 18.4665 3.75 17.5 3.75Z" fill="#8787A1"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M3.25 12C3.25 10.2051 4.70509 8.75 6.5 8.75C8.29491 8.75 9.75 10.2051 9.75 12C9.75 13.7949 8.29491 15.25 6.5 15.25C4.70509 15.25 3.25 13.7949 3.25 12ZM6.5 10.25C5.53351 10.25 4.75 11.0335 4.75 12C4.75 12.9665 5.53351 13.75 6.5 13.75C7.46649 13.75 8.25 12.9665 8.25 12C8.25 11.0335 7.46649 10.25 6.5 10.25Z" fill="#8787A1"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M15.6414 6.39859C15.8561 6.75285 15.7429 7.21404 15.3886 7.42867L9.05806 11.2641C8.70379 11.4788 8.2426 11.3656 8.02797 11.0113C7.81333 10.657 7.92652 10.1959 8.28079 9.98122L14.6113 6.14577C14.9656 5.93113 15.4268 6.04432 15.6414 6.39859Z" fill="#8787A1"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M8.02374 12.9004C8.23446 12.5438 8.69437 12.4256 9.05098 12.6363L15.7212 16.5778C16.0778 16.7885 16.196 17.2484 15.9853 17.605C15.7746 17.9616 15.3147 18.0799 14.9581 17.8692L8.28788 13.9277C7.93127 13.7169 7.81301 13.257 8.02374 12.9004Z" fill="#8787A1"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M17.5 16.75C16.5335 16.75 15.75 17.5335 15.75 18.5C15.75 19.4665 16.5335 20.25 17.5 20.25C18.4665 20.25 19.25 19.4665 19.25 18.5C19.25 17.5335 18.4665 16.75 17.5 16.75ZM14.25 18.5C14.25 16.7051 15.7051 15.25 17.5 15.25C19.2949 15.25 20.75 16.7051 20.75 18.5C20.75 20.2949 19.2949 21.75 17.5 21.75C15.7051 21.75 14.25 20.2949 14.25 18.5Z" fill="#8787A1"/>
   </svg>
);

export default Share;