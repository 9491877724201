import React from 'react';

interface IProps {
   className: string;
   onClick?: () => void;
}

const Close: React.FC<IProps> = ({ className, onClick }) => (
   <svg onClick={onClick} className={className} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.3335 9.33325L22.6668 22.6666" stroke="#8787A1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.3335 22.6666L22.6668 9.33325" stroke="#8787A1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
   </svg>
);

export default Close;