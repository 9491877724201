import React from 'react';

interface IProps {
   variant?: '#FFFFFF' | '#333333';
}

const Plus: React.FC<IProps> = ({ variant = '#333333' }) => (
   <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M12.0311 4.25C12.4453 4.25054 12.7806 4.58677 12.7801 5.00098L12.7617 19.001C12.7612 19.4152 12.4249 19.7505 12.0107 19.75C11.5965 19.7495 11.2612 19.4132 11.2617 18.999L11.2801 4.99902C11.2806 4.5848 11.6168 4.24946 12.0311 4.25Z" fill={variant}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M4.25 12C4.25 11.5858 4.58579 11.25 5 11.25H19C19.4142 11.25 19.75 11.5858 19.75 12C19.75 12.4142 19.4142 12.75 19 12.75H5C4.58579 12.75 4.25 12.4142 4.25 12Z" fill={variant}/>
   </svg>
);

export default Plus;