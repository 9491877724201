import React from 'react';

interface IProps {
   variant?: '#FFFFFF' | '#333333' | '#196DEB';
}

const ArrowRight: React.FC<IProps> = ({ variant = '#333333' }) => (
   <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M2.25 12C2.25 11.5858 2.58579 11.25 3 11.25H21C21.4142 11.25 21.75 11.5858 21.75 12C21.75 12.4142 21.4142 12.75 21 12.75H3C2.58579 12.75 2.25 12.4142 2.25 12Z" fill={variant} />
      <path fillRule="evenodd" clipRule="evenodd" d="M14.4697 5.46967C14.7626 5.17678 15.2374 5.17678 15.5303 5.46967L21.5303 11.4697C21.8232 11.7626 21.8232 12.2374 21.5303 12.5303L15.5303 18.5303C15.2374 18.8232 14.7626 18.8232 14.4697 18.5303C14.1768 18.2374 14.1768 17.7626 14.4697 17.4697L19.9393 12L14.4697 6.53033C14.1768 6.23744 14.1768 5.76256 14.4697 5.46967Z" fill={variant} />
   </svg>
);

export default ArrowRight;