import React from 'react';

const Reply: React.FC = () => (
   <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_906_24511" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
         <rect width="16" height="16" fill="#D9D9D9"/>
      </mask>
      <g mask="url(#mask0_906_24511)">
         <path d="M5.33203 11.3332L1.33203 7.33319L5.33203 3.33319L6.28203 4.26652L3.21536 7.33319L6.28203 10.3999L5.33203 11.3332ZM13.332 12.6665V9.99986C13.332 9.4443 13.1376 8.97208 12.7487 8.58319C12.3598 8.1943 11.8876 7.99986 11.332 7.99986H7.21536L9.61536 10.3999L8.66536 11.3332L4.66536 7.33319L8.66536 3.33319L9.61536 4.26652L7.21536 6.66652H11.332C12.2543 6.66652 13.0405 6.99141 13.6907 7.64119C14.3405 8.29141 14.6654 9.07763 14.6654 9.99986V12.6665H13.332Z" fill="#94A3B8"/>
      </g>
   </svg>
);

export default Reply;