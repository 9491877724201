import React, { lazy, Suspense } from 'react';
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import Layout from "layouts";

import Spinner from "components/Spinner";

const Home = lazy(() => import('pages/Home'));
const Blog = lazy(() => import('pages/Blog'));
const Info = lazy(() => import('pages/Info'));
const Blogs = lazy(() => import('pages/Blogs'));
const Search = lazy(() => import('pages/Search'));
const Experts = lazy(() => import('pages/Experts'));
const Hotlines = lazy(() => import('pages/Hotlines'));
const NotFound = lazy(() => import('pages/NotFound'));
const ServiceInside = lazy(() => import('pages/ServiceInside'));

const ExportWrapper = lazy(() => import('pages/Export/Export'));
const ExportMain = lazy(() => import('pages/Export/Main'));
const ExportBusiness = lazy(() => import('pages/Export/Business'));
const ExportInformation = lazy(() => import('pages/Export/Information'));

const AccountWrapper = lazy(() => import('pages/Account/Account'));
const AccountSettings = lazy(() => import('pages/Account/Settings'));
const AccountChat = lazy(() => import('pages/Account/Chat'));
const AccountApplications = lazy(() => import('pages/Account/Applications'));

const DiscoverWrapper = lazy(() => import('pages/Discover/Discover'));
const DiscoverMain = lazy(() => import('pages/Discover/Main'));
const DiscoverNews = lazy(() => import('pages/Discover/News'));
const DiscoverMedia = lazy(() => import('pages/Discover/Media'));

const InvestorWrapper = lazy(() => import('pages/Investor/Investor'));
const InvestorMain = lazy(() => import('pages/Investor/Main'));
const InvestorTerritory = lazy(() => import('pages/Investor/Territory'));
const InvestorLegislation = lazy(() => import('pages/Investor/Legislation'));

const SmartAdliya = lazy(() => import('pages/Mobile/SmartAdliya'));
const MahallaAdliya = lazy(() => import('pages/Mobile/MahallaAdliya'));

const App: React.FC = () => (
   <>
      <Suspense
        fallback={
           <Layout>
              <div
                 style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100vw',
                    height: '600px',
                 }}
              >
                 <Spinner/>
              </div>
           </Layout>
        }
      >
         <Routes>
            <Route
               path='/'
               element={
                  <Layout>
                     <Home/>
                  </Layout>
               }
            />

            <Route
               path='/info/:page'
               element={
                  <Layout>
                     <Info />
                  </Layout>
               }
            />

            <Route
               path='/blogs/:type/:id'
               element={
                  <Layout>
                     <Blogs />
                  </Layout>
               }
            />

            <Route
               path='/search'
               element={
                  <Layout>
                     <Search />
                  </Layout>
               }
            />

            <Route
               path='/experts'
               element={
                  <Layout>
                     <Experts />
                  </Layout>
               }
            />

            <Route
               path='/hotlines'
               element={
                  <Layout>
                     <Hotlines />
                  </Layout>
               }
            />

            <Route
               path='/blog/:id'
               element={
                  <Layout>
                     <Blog />
                  </Layout>
               }
            />

            <Route
               path='/service/:id'
               element={
                  <Layout>
                     <ServiceInside />
                  </Layout>
               }
            />

            <Route
               path='/discover'
               element={
                  <Layout>
                     <DiscoverWrapper />
                  </Layout>
               }
            >
               <Route path='/discover/main' element={<DiscoverMain />} />

               <Route path='/discover/news' element={<DiscoverNews />} />

               <Route path='/discover/media' element={<DiscoverMedia />} />
            </Route>

            <Route
               path='/export'
               element={
                  <Layout>
                     <ExportWrapper />
                  </Layout>
               }
            >
               <Route path='/export/main' element={<ExportMain />} />

               <Route path='/export/information' element={<ExportInformation />} />

               <Route path='/export/business' element={<ExportBusiness />} />
            </Route>

            <Route
               path='/investor'
               element={
                  <Layout>
                     <InvestorWrapper />
                  </Layout>
               }
            >
               <Route path='/investor/main' element={<InvestorMain />} />

               <Route path='/investor/legislation' element={<InvestorLegislation />} />

               <Route path='/investor/territory' element={<InvestorTerritory />} />
            </Route>

            <Route
               path='/account'
               element={
                  <Layout>
                     <AccountWrapper />
                  </Layout>
               }
            >
               <Route path='/account/applications' element={<AccountApplications />} />

               <Route path='/account/chat' element={<AccountChat />} />

               <Route path='/account/settings' element={<AccountSettings />} />
            </Route>

            <Route
               path='/smart-adliya'
               element={<SmartAdliya />}
            />

            <Route
               path='/mahalla-adliya'
               element={<MahallaAdliya />}
            />

            <Route
               path='*'
               element={
                  <Layout>
                     <NotFound />
                  </Layout>
               }
            />
         </Routes>
      </Suspense>

      <ToastContainer autoClose={1500} />
   </>
);

export default App;